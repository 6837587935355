import { withLayout } from '@/components/template/Layout'
import SimpleTabs, { SimpleTabProps } from '@/components/template/SimpleTabs'
import { siteNodeApi } from '@/ghgApi'
import { findOrganizationSitesObjectives, getOrganizationSitesObjective } from '@/services/store'
import useStore from '@/zustand/sotre'
import React, { useEffect, useState, CSSProperties } from 'react'
import General from './tabs/general'
import Objectives from './tabs/objectives'
import Views from './tabs/views'
import IdeaManagement from './tabs/ideaManagement'
import { SiteNode } from '@/openapi'

const cssFixedTab: CSSProperties = {
    position: 'fixed',
    top: 80,
    zIndex: 1,
    backgroundColor: '#fff',
    width: '100%',
    maxWidth: 1600,
}

function CompaniesManagement() {
    const { storeState, setStoreState, analysisState } = useStore()
    const [siteNodes, setSiteNodes] = useState<SiteNode[]>([])
    const [tabValue, setTabValue] = useState(0)

    useEffect(() => {
        if (storeState.organization) {
            getSiteObjectiveByYear(analysisState.date.year)
        }
    }, [analysisState.date.year])

    useEffect(() => {
        fetchSiteNodes()
    }, [storeState.organization])

    const fetchSiteNodes = async () => {
        if (!storeState.organization) {
            return
        }
        try {
            const res = await siteNodeApi.getSiteNodes(storeState.organization.id)
            const { siteNodes } = res.data
            if (siteNodes) {
                setSiteNodes(siteNodes)
            }
        } catch (err) {
            console.warn(err)
        }
    }

    const getSiteObjectiveByYear = async (year: number) => {
        if (storeState.organization) {
            const newStoreState = { ...storeState }
            const startMonth = storeState.organization.startMonth
            if (newStoreState.organization && startMonth) newStoreState.organization.startMonth = +startMonth
            const organizationSitesObjective = await getOrganizationSitesObjective()
            const sitesMap = findOrganizationSitesObjectives(organizationSitesObjective.sites, year)
            newStoreState.sites.map((d) => Object.assign(d, sitesMap[d.id]))
            setStoreState(newStoreState)
        }
    }

    const tabList: SimpleTabProps[] = [
        { label: '基本情報編集', content: <General siteNodes={siteNodes} /> },
        { label: '目標編集', content: <Objectives resData={storeState} /> },
        { label: '情報照会', content: <Views /> },
        { label: 'IDEA管理', content: <IdeaManagement /> },
    ]
    const handleTabChange = (tabValue: number) => setTabValue(tabValue)

    return (
        <div>
            <title>企業情報｜Emission View</title>
            <SimpleTabs
                tabs={tabList}
                value={tabValue}
                handleChange={(tab: number) => handleTabChange(tab)}
                tabFixed={cssFixedTab}
            />
        </div>
    )
}

export default withLayout(CompaniesManagement)
